export const useInputImageRenderer = (inputEl, callback) => {
  const inputImageRenderer = () => {
    const file = inputEl.value.files[0]
    const reader = new FileReader()
    reader.addEventListener(
      // 'onloaded',
      'load',
      () => {
        callback(reader.result)
      },
      false,
    )
    if (file) {
      reader.readAsDataURL(file)
    }
  }
  return {
    inputImageRenderer,
  }
}

export const blobToBase64second = image => new Promise((resolve, reject) => {
  try {
    const fileReader = new FileReader()

    fileReader.onload = () => {
      const img = new Image()

      img.onload = () => {
        resolve({ width: img.width, height: img.height, imgSrc: fileReader.result })
      }

      img.src = fileReader.result
    }

    fileReader.readAsDataURL(image)
  } catch (e) {
    reject(e)
  }
})
export const blobToBase64 = blob => new Promise(resolve => {
  const reader = new FileReader()
  reader.onloadend = () => {
    // console.log(reader.result)
    resolve(reader.result)
  }
  reader.readAsDataURL(blob)
})

export const _ = null
