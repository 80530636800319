<template>
  <b-card>
    <!-- {{ this.$store.state.products.productGeneral }} -->
    <!-- media -->
    <b-row>
      <b-col
        lg="8"
        class="mb-2"
      >
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                :src="product.img"
                rounded
                height="80"
              />
            </b-link>
            <!--/ avatar -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="$refs.refInputEl.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="profileFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @input="inputImageRenderer"
            />
            <!--/ upload button -->

            <!-- reset -->
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              class="mb-75 mr-75"
            >
              Reset
            </b-button> -->
            <!--/ reset -->
            <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
          </b-media-body>
        </b-media>
      </b-col>
    </b-row>
    <!--/ media -->
    <!-- form -->
    <b-form
      @submit.prevent="onSubmit"
      @reset="onReset"
    >
      <b-row>
        <b-col
          cols="2"
        >
          <b-form-group
            label="ID"
            label-for="product-codProduct"
          >
            <b-form-input
              id="product-cod"
              placeholder="Cod."
              name="codProduct"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="10"
        >
          <b-form-group
            label="Nome"
            label-for="product-name"
          >
            <b-form-input
              id="product-name"
              placeholder="nome"
              name="username"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="6"
        >
          <b-form-group
            label="Responsabile"
            label-for="product-Responsabile"
          >
            <b-form-input
              id="product-Responsabile"
              placeholder="Responsabile"
              name="username"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="6"
        >
          <b-form-group
            label="Email"
            label-for="product-name"
          >
            <b-form-input
              id="product-name"
              placeholder="Username"
              name="username"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
        >
          <b-form-group
            label="Descrizione"
            label-for="product-description"
          >
            <b-form-textarea
              id="product-description"
              placeholder="Descrizione..."
              rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex flex-row justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            type="submit"
          >
            Salva
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  VBToggle, BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BFormTextarea,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      product: {
        codice: '',
        nome: '',
        tipo: '',
        unita: '',
        unitaUtilizzata: '',
        setAttributi: '',
        categorie: [],
        statoServizio: '',
        descrizione: '',
        note: '',
        // eslint-disable-next-line global-require
        img: require('@/assets/images/prodottiVET/croccantini.jpg'),
      },
      setAttributi: [
        { title: 'SET 1' },
        { title: 'SET 2' },
        { title: 'SET 3' },
        { title: 'SET 4' },
        { title: 'SET 5' },
      ],
      provenienza: [
        { title: 'Acque Dolci' },
        { title: 'Allevamento' },
        { title: 'Mare' },
      ],
      additvi: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'E221', 'E526/E500'],
      metodoCattura: [
        { title: 'D - Reti da traino' },
        { title: 'E - Reti da circuizione' },
        { title: 'F - AMI e palancari' },
        { title: 'G - Nassi e trappole' },
        { title: 'H - Reti a tremaggio' },
        { title: 'L - Reti a postafissa' },
        { title: 'M - Reti da posta' },
        { title: 'N - Sciabbiche' },
        { title: 'O - Reti a strascico' },
        { title: 'P - Reti da imbrocco' },
      ],
      optionWeigth: [
        { title: 'KG' },
        { title: 'CAD' },
      ],
      optionType: ['Prodotto Semplice', 'Servizio', 'Unificato', 'Bundle', 'Configurabile', 'Virtuale', 'Downloadable'],
      profileFile: null,
      optionCat: ['Mangimi', 'Farmacia', 'etc'],
      optionSubCat: [],
      stateService: ['Sviluppo', 'Operativo', 'Ritirato'],
    }
  },
  methods: {
    onReset() {
      // console.log('resetForm')
    },
    onSubmit() {
      // console.log(this.product)
      this.$router.push({ name: 'details-products', params: { id: this.product.codice } })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
