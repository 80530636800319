<template>
  <b-tabs
    vertical
    pills
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
        />
        <span class="font-weight-bold">Generali</span>
      </template>

      <GeneralDetailsOrganization />
    </b-tab>
    <b-tab
      disabled
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Responsabile</span>
      </template>

    </b-tab>
    <!-- info -->
    <b-tab disabled>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Team</span>
      </template>

    </b-tab>
    <b-tab disabled>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
        />
        <span class="font-weight-bold">Dispositivi</span>
      </template>

    </b-tab>
    <!--/ general tab -->
    <b-tab disabled>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Magazzini</span>
      </template>

    </b-tab>
    <b-tab disabled>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Prodotti</span>
      </template>

    </b-tab>
    <b-tab disabled>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Statistiche</span>
      </template>

    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import GeneralDetailsOrganization from './tabs/GeneralDetailsOrganization.vue'

export default {
  components: {
    BTabs,
    BTab,
    GeneralDetailsOrganization,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeMount() {
    this.account = {
      general: {
        // eslint-disable-next-line global-require
        avatar: require('@/assets/images/portrait/small/avatar-s-11.jpg'),
        username: 'johndoe',
        fullName: 'John Doe',
        email: 'granger007@hogward.com',
        company: 'Crystal Technologies',
      },
      info: {
        bio: '',
        dob: null,
        country: 'USA',
        website: '',
        phone: 6562542568,
      },
      social: {
        socialLinks: {
          twitter: 'https://www.twitter.com',
          facebook: '',
          google: '',
          linkedIn: 'https://www.linkedin.com',
          instagram: '',
          quora: '',
        },
        connections: {
          twitter: {
            // eslint-disable-next-line global-require
            profileImg: '/img/avatar-s-11.14cf1734.jpg',
            id: 'johndoe',
          },
          google: {
            // eslint-disable-next-line global-require
            profileImg: '/img/avatar-s-11.14cf1734.jpg',
            id: 'luraweber',
          },
          facebook: {},
          github: {},
        },
      },
      notification: {
        commentOnArticle: true,
        AnswerOnForm: true,
        followMe: false,
        newAnnouncements: true,
        productUpdates: true,
        blogDigest: false,
      },
    }
    this.options = {
      general: {
        // eslint-disable-next-line global-require
        img: require('@/assets/images/prodottiVET/croccantini.jpg'),
        // img: '/assets/images/prodottiVET/croccantini.jpg',
        active: false,
        name: 'Croccantini',
        codProduct: '0001',
        shortDescription: 'Croccantini vegani per cani diabetici',
        type: 'Servizio',
        categories: ['General', 'Mangimi', 'Consumabili'],
        subCategories: ['sub-1', 'sub-2', 'sub-3'],
        unit: 'kg',
        quantity: '2',
        price: [{ name: 'Privati', price: 10 }, { name: 'Aziende', price: 9 }, { name: 'Partiners', price: 8.5 }],
        longDescription: '',
      },
      property: {
        productCod: [
          {
            id: 1, value: 'Matrice', code: '0101010101', type: 'DataMatrix', nameCode: 'prova1',
          },
          {
            id: 2, value: 'QRCode', code: '1olpdsame2', type: 'QRCode', nameCode: 'prova1',
          },
          {
            id: 3, value: 'Cod SDI', code: '129403', type: 'Code128', nameCode: 'prova1',
          },
          {
            id: 4, value: 'Cod a barre2', code: '0101010101121231', type: 'Code128A', nameCode: 'prova1',
          },
          {
            id: 5, value: 'Ean8', code: '50123452', type: 'Ean8', nameCode: 'prova1',
          },
        ],
        iva: { value: '22 %' },
        link: 'www.google.com',
        producer: 'VETPET',
      },
      fisic: {
        dimension: [
          {
            module: 'Larghezza',
            netto: '0.70',
          },
          {
            module: 'Altezza',
            netto: '0.40',
          },
          {
            module: 'Profondita',
            netto: '0.30',
          },
        ],
        weight: [
          {
            netto: 10,
            lordo: 11.5,
          },
        ],
      },
      warehouse: {
        quantity: 18,
      },
    }
  },
}
</script>
